<template>
  <div class="proposal">
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />
    <v-add-tax-modal
      :show.sync="isShowTaxModal"
      @close="closeTaxModal"
      @saved="savedTaxModal"
    />

    <proposal-email-slide-in
      v-if="project"
      :project.sync="project"
      :mode.sync="emailEditMode"
      :show.sync="showEmailSlide"
      :email.sync="email"
      @close="hideEmailSlideIn"
      @saved="savedEmailSlideIn"
    />

    <v-video-modal
      name="creation"
      title="Proposal creation"
      description="A video to help you get started."
      step="proposal-edit"
      thumbnail="proposal-creation.png"
      embed="1lhjGix1lE0"
      :show.sync="isShowVideoModal"
      @close="closeVideoModal"
    />

    <modal
      name="projectDateModal"
      class="modal overflow-visible"
      width="90%"
      height="240"
      :max-width="500"
      :adaptive="true"
    >
      <div class="flex justify-between">
        <div class="title">
          Choose a project date
        </div>
        <div
          class="close "
          @click="$modal.hide('projectDateModal')"
        />
      </div>

      <p class="leading-normal">
        One of your payment schedule requires a project date, <br> please pick project date before you can save this proposal.
      </p>
      <div
        v-if="!loading"
        class="flex mt-5 items-center"
      >
        <div class="w-5/12">
          <v-date-picker
            class="datepicker"
            v-model="projectDate"
            name="project_date"
            :popover="{ visibility: 'click' }"
            :input-props="{
              placeholder: &quot;Date of project&quot;,
              readonly: true
            }"
            :masks="{L: 'WWW, MMM D, YYYY'}"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                type="text"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
      </div>
      <div class="flex mt-5 items-center">
        <div class="w-3/12">
          <v-button
            class="green-btn"
            @click="addProjectDate"
          >
            Continue
          </v-button>
        </div>
        <div class="w-3/12">
          <div
            class="underline text-green cursor-pointer ml-5 md:ml-0"
            @click="$modal.hide('projectDateModal')"
          >
            Cancel
          </div>
        </div>
      </div>
    </modal>

    <v-inner-header
      type="proposal"
      mode="edit"
      :item="proposal"
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :user-might-update.sync="userMightUpdate"
      :redirect="`/projects/${$route.params.id}`"
      :template="false"
      @click="alertExit"
      @share="copyShareLink"
      @preview="saveAndPreview"
      @deleteItem="deleteItem"
      @markAsSent="markAsSent"
      @markAsUnsent="markAsUnsent"
    />
    <div class="pt-16">
      <div class="flex">
        <div
          v-if="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32 text-center"
          >
        </div>
        <div
          v-else
          id="lead-form-setting"
          class="w-full"
        >
          <proposal-contact-slide-in
            v-if="contact"
            :show.sync="showContactSlide"
            :contact.sync="contact"
            @close="hideContactSlideIn"
            @saved="savedContactSlideIn"
          />
          <div class="bg-grey-black px-4">
            <div class="max-w-xl mx-auto text-white pt-6">
              <div class="flex">
                <div class="w-full">
                  <div class="flex items-center">
                    <div class="w-3/4">
                      <h1 class="text-xl text-white">
                        Proposal options
                      </h1>
                    </div>
                    <div class="block md:hidden w-1/3 text-right">
                      <div class="inline-block">
                        <v-tutorial-button
                          mode="dark"
                          @click="showVideoModal"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="name != '' && name != null"
                    class="input-group mt-5 mb-3"
                  >
                    <input
                      v-model="name"
                      type="text"
                      class="w-full"
                      name="name"
                      placeholder="Name of the proposal"
                    >
                  </div>
                  <div>
                    <!-- TAX -->
                    <div
                      class="options-item flex flex-wrap items-center"
                      style="height:42px;"
                    >
                      <div
                        v-if="hasTaxes"
                        class="inline-block mr-10"
                      >
                        <v-checkbox
                          id="isTaxEnable"
                          v-model="isTaxEnable"
                          :checked="isTaxEnable"
                          type="dark"
                          label="Apply tax"
                        />
                        <div
                          v-show="isTaxEnable"
                          class="inline-block ml-8"
                        >
                          <v-row class="items-center">
                            <v-col>
                              <div class="select-container">
                                <select v-model="isTaxPriceIncluded">
                                  <option
                                    :value="true"
                                  >
                                    Tax included
                                  </option>
                                  <option
                                    :value="false"
                                  >
                                    Tax excluded
                                  </option>
                                </select>
                              </div>
                              <v-help-tooltip
                                :options="tooltipContent"
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <div
                        v-else
                        class="inline-block  mr-10"
                      >
                        <a
                          class="cursor-pointer text-green underline"
                          @click="showTaxModal"
                        >
                          Add a tax class
                        </a>
                      </div>
                      <div>
                        <v-checkbox
                          id="isInvoiceGenerated"
                          :checked="isInvoiceGenerated"
                          v-model="isInvoiceGenerated"
                          type="dark"
                          label="Generate an invoice upon signing of this quote"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Steps -->
            <div class="proposal-steps overflow-hidden mt-16 md:mt-4 max-w-xl mx-auto">
              <div class="flex items-center justify-between">
                <div class="w-full md:w-10/12 justify-between md:justify-start flex ">
                  <div class="w-1/3 md:w-auto">
                    <v-checkbox-tab
                      id="proposal"
                      v-model="hasProposal"
                      active="false"
                      label="Proposal"
                      item-step="1"
                      :current-step.sync="step"
                      @switchStep="switchStep(1)"
                      @activeTab="activeTab(1)"
                    />
                  </div>
                  <div class="w-1/3 md:w-auto">
                    <v-checkbox-tab
                      id="isPaymentScheduleActive"
                      v-model="isPaymentScheduleActive"
                      label="Payment"
                      item-step="2"
                      :current-step.sync="step"
                      class="px-2"
                      @change.native="calculateSteps(2)"
                      @switchStep="switchStep(2)"
                      @activeTab="activeTab(2)"
                    />
                  </div>
                  <div class="w-1/3 md:w-auto">
                    <v-checkbox-tab
                      id="isContractIncluded"
                      v-model="isContractIncluded"
                      label="Contract"
                      item-step="3"
                      :current-step.sync="step"
                      @change.native="calculateSteps(3)"
                      @switchStep="switchStep(3)"
                      @activeTab="activeTab(3)"
                    />
                  </div>
                </div>
                <div class="pb-2 hidden md:block">
                  <v-tutorial-button
                    mode="dark"
                    @click="showVideoModal"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="proposal-form bg-white shadow max-w-xl mx-auto">
            <div class="flex bg-white header">
              <div class="w-1/2">
                Status:
                <span
                  v-if="actionDates"
                  class="item-invoice-state capitalize"
                  :class="proposal.state"
                >
                  <span v-if="proposal.state == 'draft'">UNSENT</span>
                  <span v-else>{{ proposal.state }}</span>
                </span>
                <span
                  v-else
                  class="draft item-invoice-state capitalize"
                >
                  UNSENT
                </span>
              </div>
              <div
                class="w-1/2 text-right text-grey-darker"
              >
                <div
                  v-if="proposal.locale"
                  class="static-tag orange"
                >
                  {{ proposal.locale | viewLocale }}
                </div>
                <!-- <div v-if="actionDates">
                  {{ actionDates[0].label }} {{ actionDates[0].timestamp | humanDateWithTimeStamp(timezone) }} - <a
                    class="underline"
                    href="#history"
                  >View all history</a>
                </div> -->
              </div>
            </div>
            <div
              v-show="step == 1"
              id="step-1"
            >
              <div class="max-w-xl mx-auto  py-8 ">
                <div class="flex flex-wrap px-8 ">
                  <div class="w-full md:w-3/5">
                    <div
                      v-if="logo.path"
                      class="logo"
                    >
                      <img
                        :src="logo.path"
                        width="100%"
                        :style="[logo.width > logo.height ? {'max-width' : '278px'} : '', logo.width < logo.height ? { 'width' : 'auto', 'max-height':'250px'} : '', logo.width == logo.height ? { 'width' : '88px', 'height':'88px'} : '' ]"
                      >
                    </div>
                    <div
                      v-else
                      class="flex items-center text-grey-semi-light mb-4 "
                    >
                      <div class="w-2/5 lg:w-4/12">
                        Logo <v-help-tooltip
                          :options="logoTooltipContent"
                        />
                      </div>
                      <div class="w-3/5 lg:w-8/12 ">
                        <div
                          class="border-grey border-dashed border cursor-pointer hover:bg-grey-lighter"
                          style="width:88px; height:88px;"
                          @click="$refs.file.click()"
                        >
                          <img
                            v-show="uploadLoading"
                            src="@/assets/img/icons/loader.svg"
                            width="40"
                            style="transform:translate(-50%,-50%); top:50%; left:50%; "
                          >
                        </div>
                        <input
                          ref="file"
                          type="file"
                          style="display:none;"
                          @change="fileUpload"
                        >
                      </div>
                    </div>

                    <div class="flex flex-wrap items-center text-grey-semi-light mb-4">
                      <div class="w-full md:w-2/5 lg:w-4/12">
                        {{ $translate('quote', proposal.locale) }}
                      </div>
                      <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                        {{ user.companySettings.numberPrefix }}
                        <input
                          ref="referenceNumber"
                          v-model="referenceNumber"
                          class="w-24 pr-3 mr-2 ref-number remove-arrow"
                          type="text"
                          name="referenceNumber"
                          @keypress="isNumber($event)"
                        >
                        <div class="inline-block mt-2 md:mt-0">
                          last used: {{ lastReferenceNumber }}
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-wrap items-center text-grey-semi-light mb-4">
                      <div class="w-full md:w-2/5 lg:w-4/12">
                        {{ $translate('issued', proposal.locale) }}
                      </div>
                      <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                        <v-date-picker
                          class="datepicker max-width"
                          v-model="issuedAt"
                          name="issuedAt"
                          :popover="{ visibility: 'click' }"
                          :input-props="{
                            readonly: true
                          }"
                          :masks="{L: 'WWW, MMM D, YYYY'}"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <input
                              type="text"
                              :value="inputValue"
                              v-on="inputEvents"
                            />
                          </template>
                        </v-date-picker>
                      </div>
                    </div>

                    <div class="flex flex-wrap items-center text-grey-semi-light mb-4">
                      <div class="w-full md:w-2/5 lg:w-4/12">
                        {{ $translate('dueDate', proposal.locale) }}
                      </div>
                      <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                        <v-date-picker
                          class="datepicker max-width"
                          v-model="validUntil"
                          name="validUntil"
                          :popover="{ visibility: 'click' }"
                          :input-props="{
                            readonly: true
                          }"
                          :masks="{L: 'WWW, MMM D, YYYY'}"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <input
                              type="text"
                              :value="inputValue"
                              v-on="inputEvents"
                            />
                          </template>
                        </v-date-picker>
                      </div>
                    </div>

                    <div class="flex flex-wrap items-center text-grey-semi-light mb-4">
                      <div class="w-full md:w-2/5 lg:w-4/12">
                        {{ $translate('subject', proposal.locale) }}
                      </div>
                      <div class="mt-3 md:mt-0 w-full md:w-3/5 lg:w-8/12 ">
                        <input
                          v-model="subject"
                          type="text"
                          name="subject"
                          placeholder="Subject of the proposal"
                        >
                      </div>
                    </div>

                  </div>

                  <div class="w-full md:w-2/5 md:text-right text-sm mt-5 md:mt-0">
                    <div class="flex">
                      <div class="w-1/3 md:w-1/2 md:text-right">
                        <div class="mr-12 text-grey-semi-light leading-loosen">
                          {{ $translate('from', proposal.locale) }}
                        </div>
                      </div>
                      <div class="w-2/3 md:w-1/2  text-left text-grey-darker leading-loosen break-words">
                        <strong class="text-black">{{ user.company.name }}</strong>
                        <pre style="font-family:inherit">{{ user.company.address }}</pre>
                        <pre
                          v-if="user.company.city"
                          style="font-family:inherit"
                        >{{ user.company.city }}<span v-if="user.company.postal_code">,</span> {{ user.company.postal_code }}</pre>
                        {{ user.company.country }}
                      </div>
                    </div>
                    <div
                      v-if="user.companySettings.paymentInfo"
                      class="flex flex-wrap"
                    >
                      <div class="w-1/3 md:w-1/2 md:text-right" />
                      <div class="w-2/3 md:w-1/2 text-left text-grey-darker leading-loosen break-words">
                        <pre>{{ user.companySettings.paymentInfo }}</pre>
                      </div>
                    </div>
                    <div class="flex mt-5">
                      <div class="w-1/3 md:w-1/2 md:text-right">
                        <div class="mr-12 text-grey-semi-light leading-loosen">
                          {{ $translate('to', proposal.locale) }}
                        </div>
                      </div>
                      <div class="w-2/3 md:w-1/2  text-left">
                        <div
                          v-if="contact"
                          class="text-grey-darker leading-loosen break-words"
                        >
                          <strong v-if="contact.company_name"> {{ contact.company_name }}<br></strong>
                          <div v-if="contact.same_as_billing_address">
                            {{ contact.full_name }}<br>
                            <pre style="font-family:inherit">{{ contact.address }}</pre>
                            <pre
                              v-if="contact.city"
                              style="font-family:inherit"
                            >{{ contact.city }}<span v-if="contact.postal_code">,</span> {{ contact.postal_code }}</pre>
                            {{ contact.country }} <br>
                          </div>
                          <div v-if="!contact.same_as_billing_address">
                            {{ contact.billing_first_name }} {{ contact.billing_last_name }}<br>
                            <pre style="font-family:inherit">{{ contact.billing_address }}</pre>
                            <pre
                              v-if="contact.billing_city"
                              style="font-family:inherit"
                            >{{ contact.billing_city }}<span v-if="contact.billing_postal_code">,</span> {{ contact.billing_postal_code }}</pre>
                            {{ contact.billing_country }} <br>
                          </div>
                          <span v-if="contact.vat">{{ contact.vat }} <br></span>
                          <a
                            class="cursor-pointer blue underline"
                            @click="editContact"
                          > Edit </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-12 w-full px-8">
                  <h3>
                    Deliverables
                  </h3>
                  <p class="note">
                    Items that are included in the invoice, that a client has is not able to select.
                  </p>
                  <div class="note mb-3">
                    <strong>Tip</strong>: You can use tokens like <strong>@clientFirstName</strong> here as well. <a href="https://intercom.help/octoa/en/articles/7135447-using-tokens-in-the-octoa-tool" target="_blank">Learn more</a>.
                  </div>
                </div>
                <div class="heading">
                  <div class="flex px-8 w-full font-bold">
                    <div class="w-1/2">
                      {{ $translate('item', proposal.locale) }}
                    </div>
                    <div class="w-1/2 px-2 hidden md:flex flex-grow">
                      <div class="pr-2">
                        {{ $translate('quantity', proposal.locale) }}
                      </div>
                      <div
                        v-if="isTaxEnable"
                        class="w-32 pl-2 pr-8"
                      >
                        {{ $translate('tax', proposal.locale) }}
                      </div>
                      <div class="px-2">
                        {{ $translate('price', proposal.locale) }}
                      </div>
                      <div class="pl-2 w-full text-right">
                        {{ $translate('total', proposal.locale) }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Required list items -->
                <div class="px-4">
                  <!-- Draggable -->
                  <draggable
                    :list="listofItems"
                    handle=".drag"
                    drag-class="drag-helper"
                    animation="200"
                  >
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <div
                        v-for="(item, index) in listofItems"
                        :key="item.id"
                      >
                        <div class="draggable-item">
                          <div class="drag">
                            <div class="bg">
                              <img
                                src="@/assets/img/icons/scroll.svg"
                                width="15"
                              >
                            </div>
                          </div>
                          <div
                            class="delete"
                            @click="removeItem(index)"
                          >
                            <div class="bg">
                              <img
                                src="@/assets/img/icons/delete.svg"
                                width="25"
                              >
                            </div>
                          </div>
                          <div
                            class="quoteItem flex flex-wrap w-full px-2 pt-6 pb-2"
                            style="background:transparent;"
                          >
                            <div class="w-full md:w-1/2 px-2">
                              <vue-autosuggest
                                :ref="'autoSuggestId'+item.id"
                                :suggestions="suggestions"
                                :input-props="{id:'autosuggest__input',
                                               initialValue: item.name,
                                               onInputChange: autoSuggestOnInputChange,
                                               autocomplete: 'off',
                                               placeholder:'Item name'}"
                                @blur="autoSuggestBlurHandler(item)"
                                @click="autoSuggestClickHandler(item)"
                                @selected="autoSuggestSelectHandler"
                              >
                                <template slot-scope="{suggestion}">
                                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                                </template>
                              </vue-autosuggest>
                              <textarea-autosize
                                v-model="item.description"
                                placeholder="Item description"
                                class="md:hidden block mt-3 mb-3"
                                :min-height="100"
                                :max-height="300"
                              />
                            </div>

                            <div class="w-1/2 hidden md:flex justify-end ">
                              <div class="px-2">
                                <input
                                  v-model="item.quantity"
                                  type="number"
                                  min="0"
                                  class="quantity"
                                  name="quantity"
                                  @input="checkQuantity(item, $event)"
                                >
                              </div>
                              <div
                                v-if="isTaxEnable"
                                class="px-2"
                              >
                                <div class="select-container">
                                  <select v-model="item.tax">
                                    <option
                                      v-for="tax in taxes"
                                      :key="tax.id"
                                      :value="parseFloat(tax.value)"
                                      :selected="(item.tax == tax.value) ? 'selected' : ''"
                                    >
                                      {{ tax.value }}%
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="px-2 w-36">
                                <vue-numeric
                                  v-model="item.price"
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  class="small-right"
                                  :currency="currency.sign"
                                  :minus="true"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                />
                              </div>
                              <div class="px-2 flex flex-grow">
                                <div class="mt-3 pr-2 w-full text-right break-words">
                                  <vue-numeric
                                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                    :precision="2"
                                    read-only
                                    read-only-class="font-bold"
                                    :currency="currency.sign"
                                    :minus="false"
                                    :decimal-separator="currency.decimal_separator"
                                    :thousand-separator="currency.thousand_separator"
                                    :min="0"
                                    :value="calculateUnitPrice(item)"
                                  />
                                </div>
                              </div>
                            </div>

                            <!-- Mobile -->
                            <div class="quoteItem flex flex-wrap md:hidden justify-between w-full pb-5 px-2">
                              <div class="w-1/2 mb-3 pr-3">
                                <div class="font-bold mb-1">
                                  {{ $translate('quantity', proposal.locale) }}
                                </div>
                                <input
                                  v-model="item.quantity"
                                  type="number"
                                  min="0"
                                  class="quantity"
                                  name="quantity"
                                  @input="checkQuantity(item, $event)"
                                >
                              </div>
                              <div
                                v-if="isTaxEnable"
                                class="w-1/2 "
                              >
                                <div class="font-bold mb-1">
                                  {{ $translate('tax', proposal.locale) }}
                                </div>
                                <div class="select-container">
                                  <select v-model="item.tax">
                                    <option
                                      v-for="tax in taxes"
                                      :key="tax.id"
                                      :value="parseFloat(tax.value)"
                                      :selected="(item.tax == tax.value) ? 'selected' : ''"
                                    >
                                      {{ tax.value }}%
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div
                                class="w-1/2"
                                :class="{'pr-3' : isTaxEnable}"
                              >
                                <div class="font-bold mb-1">
                                  {{ $translate('price', proposal.locale) }}
                                </div>
                                <vue-numeric
                                  v-model="item.price"
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  class="small-right"
                                  :currency="currency.sign"
                                  :minus="true"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                />
                              </div>
                              <div :class="{ 'w-full' : !isTaxEnable, 'w-1/2' : isTaxEnable}">
                                <div class="font-bold mb-1">
                                  {{ $translate('total', proposal.locale) }}
                                </div>
                                <div class="mt-4">
                                  <vue-numeric
                                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                    :precision="2"
                                    read-only
                                    :currency="currency.sign"
                                    :minus="false"
                                    :decimal-separator="currency.decimal_separator"
                                    :thousand-separator="currency.thousand_separator"
                                    :min="0"
                                    :value="calculateUnitPrice(item)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="quoteItem flex px-4">
                            <div class="w-4/5 md:w-full">
                              <textarea-autosize
                                v-model="item.description"
                                placeholder="Item description"
                                class="hidden md:block mt-3 mb-3"
                                :style="[isTaxEnable ? {'max-width': '795px'} : {'max-width': '710px'}]"
                                :min-height="100"
                                :max-height="300"
                              />
                              <div>
                                <label class="mr-5 pt-2 py-3 inline-block text-grey-dark">
                                  <v-checkbox
                                    :id="`item${item.id}-isTemplate`"
                                    v-model="item.isTemplate"
                                    :checked="item.isTemplate"
                                    classes="small"
                                    label="Save item for future use"
                                  />
                                </label>
                                <label class="pt-2 py-3 inline-block text-grey-dark">
                                  <v-checkbox
                                    :id="`item${item.id}-is_quantity_selectable`"
                                    v-model="item.is_quantity_selectable"
                                    :checked="item.is_quantity_selectable"
                                    classes="small"
                                    label="Allow client to pick a quantity"
                                  />
                                </label>
                              </div>
                            </div>
                            <div
                              class="w-1/5 text-right lg:hidden"
                              @click="removeItem(index)"
                            >
                              <div class="bg">
                                <img
                                  src="@/assets/img/icons/delete.svg"
                                  width="25"
                                >
                              </div>
                            </div>
                          </div>
                          <div class="mt-3 mx-4">
                            <hr>
                          </div>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                  <!-- End Draggable -->
                </div>

                <!-- Sub total -->
                <div class="flex px-8 mt-5 pb-3">
                  <div class="w-3/5">
                    <a
                      class="underline green text-sm mt-3 mb-5 cursor-pointer"
                      @click="addItem"
                    >
                      + Add new item
                    </a>
                  </div>
                </div>
              </div>

              <!-- Additional options -->
              <div class="max-w-xl mx-auto pb-8">
                <div class="px-8">
                  <div class="flex flex-wrap">
                    <h3 class="w-full">
                      Optional item(s)
                    </h3>
                    <p class="note mb-3">
                      Items that a client can choose to add to the proposal.
                    </p>
                  </div>
                </div>
                <hr>

                <div
                  v-if="listofAdditonalItems.length > 0"
                  class="heading"
                >
                  <div class="flex px-8 w-full font-bold">
                    <div class="w-1/2">
                      {{ $translate('item', proposal.locale) }}
                    </div>
                    <div class="w-1/2 px-2 hidden md:flex flex-grow">
                      <div class="pr-2">
                        {{ $translate('quantity', proposal.locale) }}
                      </div>
                      <div
                        v-if="isTaxEnable"
                        class="w-32 pl-2 pr-8"
                      >
                        {{ $translate('tax', proposal.locale) }}
                      </div>
                      <div class="px-2">
                        {{ $translate('price', proposal.locale) }}
                      </div>
                      <div class="pl-2 w-full text-right">
                        {{ $translate('total', proposal.locale) }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Additional list items -->
                <div class="px-4">
                  <draggable
                    :list="listofAdditonalItems"
                    handle=".drag"
                    drag-class="drag-helper"
                    animation="200"
                  >
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <div
                        v-for="(item, index) in listofAdditonalItems"
                        :key="item.id"
                      >
                        <div class="draggable-item">
                          <div class="drag">
                            <div class="bg">
                              <img
                                src="@/assets/img/icons/scroll.svg"
                                width="15"
                              >
                            </div>
                          </div>
                          <div
                            class="delete"
                            @click="removeAdditionalItem(index)"
                          >
                            <div class="bg">
                              <img
                                src="@/assets/img/icons/delete.svg"
                                width="25"
                              >
                            </div>
                          </div>
                          <div
                            class="quoteItem flex flex-wrap w-full px-2 pt-6 pb-2"
                            style="background:transparent;"
                          >
                            <div class="w-full md:w-1/2 px-2">
                              <vue-autosuggest
                                :ref="'autoSuggestId'+item.id"
                                :suggestions="suggestions"
                                :input-props="{id:'autosuggest__input',
                                               initialValue: item.name,
                                               onInputChange: autoSuggestOnInputChange,
                                               autocomplete: 'off',
                                               placeholder:'Item name'}"
                                @blur="autoSuggestAdditionalBlurHandler(item)"
                                @click="autoSuggestClickHandler(item)"
                                @selected="autoSuggestAdditionalSelectHandler"
                              >
                                <template slot-scope="{suggestion}">
                                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                                </template>
                              </vue-autosuggest>

                              <textarea-autosize
                                v-model="item.description"
                                placeholder="Item description"
                                class="md:hidden block mt-3 mb-3"
                                :min-height="100"
                                :max-height="300"
                              />
                            </div>

                            <div class="w-1/2 hidden md:flex justify-end ">
                              <div class="px-2">
                                <input
                                  v-model="item.quantity"
                                  type="number"
                                  min="0"
                                  class="quantity"
                                  name="quantity"
                                  @input="checkQuantity(item, $event)"
                                >
                              </div>
                              <div
                                v-if="isTaxEnable"
                                class="px-2"
                              >
                                <div class="select-container">
                                  <select v-model="item.tax">
                                    <option
                                      v-for="tax in taxes"
                                      :key="tax.id"
                                      :value="parseFloat(tax.value)"
                                      :selected="(item.tax == tax.value) ? 'selected' : ''"
                                    >
                                      {{ tax.value }}%
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="px-2 w-36">
                                <vue-numeric
                                  v-model="item.price"
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  class="small-right"
                                  :currency="currency.sign"
                                  :minus="true"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                />
                              </div>
                              <div class="px-2 flex flex-grow">
                                <div class="mt-3 pr-2 w-full text-right break-words">
                                  <vue-numeric
                                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                    :precision="2"
                                    read-only
                                    read-only-class="font-bold text-base"
                                    :currency="currency.sign"
                                    :minus="false"
                                    :decimal-separator="currency.decimal_separator"
                                    :thousand-separator="currency.thousand_separator"
                                    :min="0"
                                    :value="calculateUnitPrice(item)"
                                  />
                                </div>
                              </div>
                            </div>
                            <!-- Mobile -->
                            <div class="flex flex-wrap md:hidden justify-between w-full pb-5 px-2">
                              <div class="w-1/2 mb-3 pr-3">
                                <div class="font-bold mb-1">
                                  {{ $translate('quantity', proposal.locale) }}
                                </div>
                                <input
                                  v-model="item.quantity"
                                  type="number"
                                  min="0"
                                  class="quantity"
                                  name="quantity"
                                  @input="checkQuantity(item, $event)"
                                >
                              </div>
                              <div
                                v-if="isTaxEnable"
                                class="w-1/2 "
                              >
                                <div class="font-bold mb-1">
                                  {{ $translate('tax', proposal.locale) }}
                                </div>
                                <div class="select-container">
                                  <select v-model="item.tax">
                                    <option
                                      v-for="tax in taxes"
                                      :key="tax.id"
                                      :value="parseFloat(tax.value).toFixed(2)"
                                      :selected="(item.tax == tax.value) ? 'selected' : ''"
                                    >
                                      {{ tax.value }}%
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div
                                class="w-1/2"
                                :class="{'pr-3' : isTaxEnable}"
                              >
                                <div class="font-bold mb-1">
                                  {{ $translate('price', proposal.locale) }}
                                </div>
                                <vue-numeric
                                  v-model="item.price"
                                  :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                  :precision="2"
                                  class="small-right"
                                  :currency="currency.sign"
                                  :minus="true"
                                  :decimal-separator="currency.decimal_separator"
                                  :thousand-separator="currency.thousand_separator"
                                />
                              </div>
                              <div :class="{ 'w-full' : !isTaxEnable, 'w-1/2' : isTaxEnable}">
                                <div class="font-bold mb-1">
                                  {{ $translate('total', proposal.locale) }}
                                </div>
                                <div class="mt-4">
                                  <vue-numeric
                                    :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                                    :precision="2"
                                    read-only
                                    :currency="currency.sign"
                                    :minus="false"
                                    :decimal-separator="currency.decimal_separator"
                                    :thousand-separator="currency.thousand_separator"
                                    :min="0"
                                    :value="calculateUnitPrice(item)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex px-4 -mt-4">
                            <div class="w-4/5 md:w-full">
                              <textarea-autosize
                                v-model="item.description"
                                placeholder="Item description"
                                class="hidden md:block mt-6 mb-3"
                                :style="[isTaxEnable ? {'max-width': '795px'} : {'max-width': '710px'}]"
                                :min-height="100"
                                :max-height="300"
                              />
                              <label class="mr-5 pt-2 py-3 inline-block text-grey-dark">
                                <v-checkbox
                                  :id="`item${item.id}-isTemplate`"
                                  v-model="item.isTemplate"
                                  :checked="item.isTemplate"
                                  classes="small"
                                  label="Save item for future use"
                                />
                              </label>
                              <label class="pt-2 py-3 inline-block text-grey-dark">
                                <v-checkbox
                                  :id="`item${item.id}-is_quantity_selectable`"
                                  v-model="item.is_quantity_selectable"
                                  :checked="item.is_quantity_selectable"
                                  classes="small"
                                  label="Allow client to pick a quantity"
                                />
                              </label>
                            </div>
                            <div
                              class="w-1/5 text-right lg:hidden"
                              @click="removeAdditionalItem(index)"
                            >
                              <div class="bg">
                                <img
                                  src="@/assets/img/icons/delete.svg"
                                  width="25"
                                >
                              </div>
                            </div>
                          </div>
                          <div class="mt-3 mx-4">
                            <hr>
                          </div>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                  <!-- End Draggable -->
                </div>

                <!-- Sub total -->
                <div class="flex flex-wrap px-8 mt-10 pb-3">
                  <div class="w-full md:w-3/5">
                    <a
                      class="underline green text-sm mt-3 mb-5 cursor-pointer"
                      @click="addAdditionalItem"
                    >
                      + Add an optional item
                    </a>
                  </div>
                  <div class="w-full md:w-2/5 text-base flex items-center flex-wrap ">
                    <div
                      v-if="isShowDiscount || discount > 0"
                      class="flex justify-between items-center w-full"
                    >
                      <div
                        class="w-1/2 "
                      >
                        {{ $translate('discount', proposal.locale) }}
                      </div>
                      <div
                        class="w-1/2 items-center text-right"
                      >
                        <vue-numeric
                          v-model="discount"
                          :precision="2"
                          class="small-right"
                          :minus="false"
                          :min="0"
                        /> &nbsp; %
                      </div>
                    </div>
                    <div
                      v-else
                      class="text-green underline cursor-pointer w-full"
                      @click="showDiscount"
                    >
                      Add discount
                    </div>
                    <hr
                      class="my-5"
                    >
                    <div class="w-1/2">
                      {{ $translate('subtotal', proposal.locale) }} <span v-if="isTaxEnable && isTaxPriceIncluded">({{ $translate('exclTax', proposal.locale) }})</span>
                    </div>
                    <div class="w-1/2 text-right">
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="viewSubTotal"
                      />
                    </div>


                    <div
                      v-if="isTaxEnable"
                      class="w-full"
                    >
                      <div
                        v-for="(tax, key) in taxClasses"
                        :key="key"
                        class="w-full flex flex-wrap mt-5"
                      >
                        <hr
                          v-if="isTaxEnable"
                          class="mb-5"
                        >
                        <div
                          v-if="isTaxEnable"
                          class="w-1/2"
                        >
                          Tax {{ key }}%
                        </div>
                        <div
                          v-if="isTaxEnable"
                          class="w-1/2 text-right"
                        >
                          <vue-numeric
                            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                            :precision="2"
                            read-only
                            :currency="currency.sign"
                            :minus="false"
                            :decimal-separator="currency.decimal_separator"
                            :thousand-separator="currency.thousand_separator"
                            :min="0"
                            :value="tax"
                          />
                        </div>
                      </div>
                    </div>

                    <hr class="my-5 thick">
                    <div class="w-1/2 text-lg font-bold">
                      {{ $translate('total', proposal.locale) }} <span v-if="isTaxEnable && isTaxPriceIncluded">({{ $translate('inclTax', proposal.locale) }})</span> ({{ currency.code }})
                    </div>
                    <div class="w-1/2 text-lg font-bold text-right">
                      <vue-numeric
                        :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
                        :precision="2"
                        read-only
                        :currency="currency.sign"
                        :minus="false"
                        :decimal-separator="currency.decimal_separator"
                        :thousand-separator="currency.thousand_separator"
                        :min="0"
                        :value="total"
                      />
                    </div>
                  </div>
                </div>

                <!-- Notes -->
                <div class="flex px-8 mt-10">
                  <textarea
                    v-model="notes"
                    placeholder="Additional notes"
                  />
                </div>
              </div>
            </div>

            <div
              v-show="step == 2"
              id="step-2"
            >
              <div class="bg-white max-w-xl mx-auto shadow py-8 rounded">
                <div class="px-8">
                  <div class="flex flex-wrap mb-3 ">
                    <h3 class="w-full">
                      Schedule payment
                    </h3>
                    <p class="note">
                      Allow customers to pay the total amount in multiple segments. It will be shown on the proposal and separate invoices can/will be generated, which have to be sent manually.
                    </p>
                  </div>
                </div>

                <div class="px-2 heading">
                  <div class="text-grey-darker flex px-4 w-full text-xs font-bold">
                    <div class="w-full md:w-2/12 px-2">
                      <span class="hidden md:block">Percentage</span>
                      <span class="block md:hidden">Item</span>
                    </div>
                    <div class="hidden md:block w-2/3 md:w-4/12 px-2">
                      Type
                    </div>
                    <div class="hidden md:block w-6/12 px-2">
                      When
                    </div>
                  </div>
                </div>

                <!-- Payment item -->
                <div
                  v-for="(item, index) in listofPaymentItems"
                  :key="item.id"
                  class="px-6"
                >
                  <div
                    v-if="!item.is_delete"
                    class="flex flex-wrap paymentItem items-center py-5 mb-3 text-sm"
                  >
                    <div class="w-2/3 md:w-2/12 px-2">
                      <div class="text-grey-darker font-bold text-xs mb-2 block md:hidden">
                        Percentage
                      </div>
                      <input
                        v-model="item.percentage"
                        type="number"
                        min="0"
                        max="100"
                        class="w-14"
                        @blur="blurPaymentItemPercent($event)"
                        @focus="$event.target.select()"
                      > &nbsp; %
                    </div>
                    <div class="w-full md:w-4/12 px-2 my-3 md:my-0">
                      <div class="text-grey-darker font-bold text-xs  mb-2 block md:hidden">
                        Type
                      </div>
                      <div class="select-container tall">
                        <select
                          v-model="item.type"
                          class="w-52"
                        >
                          <option value="on_receive">
                            Upon approval
                          </option>
                          <option value="on_completed">
                            Upon complete
                          </option>
                          <option value="project_date">
                            Project date
                          </option>
                          <option value="fixed_date">
                            Fixed date
                          </option>
                          <option
                            value="custom_date"
                          >
                            Before / After project date
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="w-full md:w-6/12 px-2">
                      <div
                        v-show="item.type == 'on_receive'"
                        class="on-recieve"
                      >
                        As soon as proposal is accepted
                      </div>
                      <div
                        v-show="item.type == 'on_completed'"
                        class="on-recieve"
                      >
                        As soon as project is marked as finished
                      </div>
                      <div
                        v-show="item.type == 'project_date'"
                        v-if="project"
                        class="project-date"
                      >
                        <span v-if="project.date">{{ project.date | humanDateNoConvert }}</span>
                        <span v-else><a
                          class="text-green underline cursor-pointer"
                          @click="showProjectDateModal"
                        >Add a project date</a></span>
                      </div>
                      <div
                        v-show="item.type == 'fixed_date'"
                        class="fixed-date w-42"
                      >
                        <v-date-picker
                          class="datepicker"
                          v-model="item.date"
                          name="fixed_date"
                          :popover="{ visibility: 'click' }"
                          :input-props="{
                            placeholder: &quot;Fixed date&quot;,
                          }"
                          :masks="{L: 'WWW, MMM D, YYYY'}"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <input
                              type="text"
                              :value="inputValue"
                              v-on="inputEvents"
                            />
                          </template>
                        </v-date-picker>
                      </div>
                      <div
                        v-show="item.type == 'custom_date'"
                        class="custom-date"
                      >
                        <div v-if="project.date">
                          <input
                            v-model="item.custom_length"
                            type="number"
                            min="1"
                            max="999"
                            class="w-8"
                          >
                          <div class="select-container tall mx-2">
                            <select v-model="item.custom_length_type">
                              <option value="days">
                                day(s)
                              </option>
                              <option value="weeks">
                                weeks(s)
                              </option>
                              <option value="months">
                                month(s)
                              </option>
                            </select>
                          </div>
                          <div class="select-container tall mt-2 md:mt-0">
                            <select v-model="item.is_before_project_date">
                              <option value="1">
                                before project date
                              </option>
                              <option value="0">
                                after project date
                              </option>
                            </select>
                          </div>
                        </div>
                        <div v-else>
                          <a
                            class="text-green underline cursor-pointer"
                            @click="showProjectDateModal"
                          >Add a project date</a>
                        </div>
                      </div>
                    </div>
                    <div class="pin-r pin-t mt-8 absolute cursor-pointer">
                      <img
                        src="@/assets/img/icons/delete.svg"
                        alt="Delete"
                        width="17"
                        @click="removePaymentItem(index)"
                      >
                    </div>
                  </div>
                </div>

                <!-- Add -->
                <div class="flex px-8 mt-6">
                  <div class="w-3/5">
                    <a
                      class="underline green text-sm mt-3 mb-5 cursor-pointer"
                      @click="addPaymentItem"
                    >
                      + Add payment item
                    </a>
                  </div>
                </div>
              </div>
            </div>


            <!-- Step 3 -->
            <div
              v-if="step == 3"
              id="step-3"
            >
              <div class="bg-white max-w-xl mx-auto shadow py-8 rounded">
                <div class="px-8">
                  <div class="flex flex-wrap items-center">
                    <div class="w-full md:w-1/2 ">
                      <h3 class="w-full">
                        Contract
                      </h3>
                      <p class="note pr-2">
                        Place your Terms and Conditions here that will become part of your proposal. People will be able to review this and sign the proposal as a whole.
                      </p>
                    </div>
                    <div class="w-full md:w-1/2 md:text-right mt-5 md:mt-0">
                      <div class="select-container border w-full md:w-3/4">
                        <select
                          v-model="selectedContractTemplate"
                          @change="changeContract"
                        >
                          <option value="0">
                            Select a contract template
                          </option>
                          <option
                            v-for="item in contracts"
                            :key="item.id"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="mt-5">
                    <!-- <v-editor
                      :allow-token="false"
                      :update-pending.sync="editorUpdatePending"
                      :editor-content.sync="contractContent"
                    /> -->
                    <v-ckeditor-token v-model="contractContent" />
                    <div class="mt-3">
                      <v-checkbox
                        id="isContractTemplate"
                        v-model="isContractTemplate"
                        :checked="isContractTemplate"
                        classes="small"
                        label="Save as template"
                      />

                      <input
                        v-show="isContractTemplate"
                        id="contractName"
                        v-model="contractName"
                        class="mt-3 w-full"
                        type="text"
                        placeholder="Name of the contract"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap mt-5 mb-10 max-w-xl px-4 lg:px-0 mx-auto text-right">
            <div
              v-if="isSaving"
              class="w-full"
            >
              <img
                src="@/assets/img/icons/loader.svg"
                width="40"
              >
            </div>
            <div
              v-else
              class="w-full"
            >
              <div class="inline-block mr-3">
                <a
                  v-if="!proposalId || (proposalId && proposal.state == 'draft')"
                  class="green underline cursor-pointer"
                  @click="save(true)"
                >
                  Save and edit later
                </a>
              </div>
              <div class="inline-block mr-3">
                <a
                  class="green underline cursor-pointer"
                  @click="saveAndPreview()"
                >
                  Save and view
                </a>
              </div>
              <button
                v-if="step != lastStep"
                class="green-btn px-10"
                @click="nextStep"
              >
                Next
              </button>

              <div class="inline-block mt-4 md:mt-0">
                <button
                  v-if="step == lastStep && (!proposalId || (proposalId && proposal.state == 'draft'))"
                  class="green-btn px-10"
                  @click="showEmailSlideIn()"
                >
                  Compose email
                </button>
                <button
                  v-if="step == lastStep && (proposalId && (proposal.state == 'sent' || proposal.state == 'accepted'))"
                  class="green-btn px-10"
                  @click="alertSave"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!loading && actionDates"
        id="history"
        class="mx-auto max-w-xl px-4"
      >
        <div class="font-bold">
          Proposal history
        </div>
        <ul>
          <li v-for="(date, index) in actionDates" v-bind:key="index">
            {{ date.label }} {{ date.timestamp | humanDateWithTimeStamp(timezone) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import auth from '@/auth'
import { parseFromTimeZone } from 'date-fns-timezone'
import ProposalContactSlideIn from './Extension/ProposalContactSlideIn'
import ProposalEmailSlideIn from './Extension/ProposalEmailSlideIn'
import { logException } from '@/helpers'
import { applyDrag, trim2Decimals, calculateDiscountTotal, calculateEachTaxClassPrice, calculateTaxTotal } from '@/utils/helpers'
import { addDays, format } from 'date-fns'
import { createCancelSource } from '@/http'
import { convertToTimeZone } from 'date-fns-timezone'
import draggable from 'vuedraggable'
import VCkeditorToken from "@/components/VCkeditorToken/VCkeditorToken.vue";

let cancelSource

export default {
  components: {
    VCkeditorToken,
    ProposalContactSlideIn,
    ProposalEmailSlideIn,
    draggable,
  },
	data(){
  	return {
      showContactSlide: false,
      hasProposal: true,
      metaTitle: 'Loading',
      hasTaxes: false,
      taxes: {},
      currency:{
        sign: '€'
      },
      logo: {path:'', width:0, height:0},
      isSaving: false,
      isSaved: false,
      loading: false,
      step:1,
      lastStep: 3,
      proposalId: null,
      contact: null,
      project: null,
      name: '',
      proposal: {},
      referenceNumber: null,
      subject: null,
      lastReferenceNumber: null,
      isTaxEnable: false,
      isTaxPriceIncluded: false,
      taxPercent: 21,
      issuedAt: new Date(),
      validUntil:  null,
      discount: 0,
      isContractIncluded: true,
      isInvoiceGenerated: true,
      isPaymentScheduleActive: true,
      selectedListId: null,
      suggestions: [],
      listofItems: [],
      listofAdditonalItems:[],
      listofPaymentItems: [],
      notes: '',
      isContractTemplate: false,
      selectedContractTemplate: 0,
      contracts: {},
      contractId: 0,
      contractName: '',
      contractContent: '',
      editorUpdatePending: false,
      isSendingMail: false,
      isPreview: false,
      user: auth.user(),
      loadTemplateId: null,
      userMightUpdate: false,
      timezone: '',
      tooltipContent: {
        content: `
          *Various countries and cases have a different way of calculating tax. For example, in Europe when servicing consumers your prices have to be including tax. When in the US the tax is added on top of the price.
        `
      },
      logoTooltipContent: {
        content: `
          Upload your logo preferably with a minimum resolution of 500px width or 500px height with extension JPG or PNG.
        `
      },
      actionDates: null,
      projectDate: null,
      saveAndEditLater: false,
      saveAndSend: false,
      isShowDiscount: false,
      uploadLoading: false,
      isShowVideoModal: false,
      isShowTaxModal: false,
      emailEditMode: 'edit',
      showEmailSlide: false,
      email: {
        emailType: 'proposal',
        emailId: null,
        isBlankEmail: false,
        autocompleteEmailTagItems: [],
        selectEmailTemplate: '',
        emailTags: [],
        templates: [],
        fromEmailView: '',
        toEmail: '',
        fromEmail: '',
        subjectEmail:'',
        contentEmail: '',
        emailUpdatePending: false,
      },
    }
  },
  computed: {
    subTotal(){
      let subTotal = this.listofItems.reduce(function (total, item) {
        return total + item.price*item.quantity
      }, 0)

      return Number((subTotal).toFixed(2))
    },
    discountTotal(){
      let total = 0
      if(this.subTotal > 0){
        total = this.subTotal * (this.discount/100)
      }
      return Number((total).toFixed(2))
    },
    discountedTotal(){
      let total = 0
      if(this.discountTotal > 0){
        total = this.subTotal - this.discountTotal
      }

      return total
    },
    taxClasses(){
      let results = calculateEachTaxClassPrice(this.listofItems, this.subTotal, this.discountedTotal, this.isTaxPriceIncluded)
      return results
    },
    taxTotal(){
      let results = calculateTaxTotal(this.listofItems, this.subTotal, this.discountedTotal, this.isTaxPriceIncluded)
      return results
    },
    viewSubTotal(){
      let subTotal = this.subTotal - this.discountTotal
      if(this.isTaxEnable && this.isTaxPriceIncluded){
        let value =  parseFloat(subTotal - this.taxTotal)

        return trim2Decimals(value)
      }

      return trim2Decimals(subTotal)
    },
    total(){
      let total = 0

      total = this.subTotal - this.discountTotal

      if(this.isTaxEnable && !this.isTaxPriceIncluded){
        total += Number(this.taxTotal)
       }

      return total
    }
  },
  async mounted(){

    let newItem = true
    this.loading = true

    await this.checkIntroVideo()
    await this.initData()

    if(this.$route.params.proposalId){
      newItem = false
      await this.getProposal(this.$route.params.proposalId)
      this.metaTitle = `Edit Proposal: ${this.referenceNumber}`
    }

    if(this.$route.query.load && this.loadTemplateId){
      newItem = false
      await this.getProposal(this.loadTemplateId, 'template')
      this.metaTitle = `Edit Proposal: ${this.referenceNumber}`
    }
    this.loading = false

    await this.loadMailTemplates()

    if(!this.loading && newItem){
      //this.addItem()
      //this.addAdditionalItem()
      this.metaTitle = 'New Proposal'
      if(this.listofPaymentItems && this.listofPaymentItems.length == 0){
        this.addPaymentItem()
      }
    }

    this.initDetectChanges()
  },
	methods: {
    isNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 48 || keyCode > 57) || keyCode === 46) {
        $event.preventDefault()
      }
    },
    checkReferenceNumber(){
      this.$toasted.clear()
      let lastRefNumber = this.referenceNumber.slice(-1)
      if (this.referenceNumber == ''){
        this.$toasted.global.general_error({
          message : 'The quote number should not be empty.'
        })
        return false
      }

      if (!/\d/.test(lastRefNumber)){
        this.$toasted.global.general_error({
          message : 'The quote number should end with a number.'
        })
        return false
      }

      return true
    },
    checkQuantity(item, $event){
      let value = $event.target.value
      if(value < 0){
        item.quantity = 0
      }
      if(value > 1000){
        item.quantity = 1000
      }
    },
    showDiscount(){
      this.isShowDiscount = true
    },
    showProjectDateModal(){
      this.$modal.show('projectDateModal')
    },
    async addProjectDate(){
      if(!this.projectDate){
        this.$toasted.global.general_error({
          message : 'Please pick a project date.'
        })
        return false
      }

      try {
        const { data } = await this.$api.get('projects').saveDate(this.$route.params.id, { date: this.projectDate})
        this.project.date = this.projectDate
      } catch(e){
        this.$toasted.global.general_error({
          message : 'Date cannot be saved.'
        })
      }

      this.$modal.hide('projectDateModal')
    },
    async alertExit(){
      if(this.userMightUpdate){
        let link = `/projects/${this.$route.params.id}`

        const canDelete = await this.$alert.confirm({
          title: 'Are you sure?',
          text: 'You might have unsaved changes, remember to save before closing.',
        })

        if (canDelete) {
          this.$router.push(link)
        }
      }
    },
    async initData(){

      const templateId = this.$store.getters['template/loadTemplateId']
      this.loadTemplateId = templateId

      // Load settings
      this.email.fromEmail = this.user.companySettings.senderEmail
      this.email.fromEmailView = `From: ${this.user.companySettings.senderName} <${this.user.companySettings.senderEmail}>`

      this.logo.path = this.user.companySettings.logo
      this.refreshLogo()

      this.currency = this.user.companySettings.currency
      this.taxes = this.user.companySettings.taxes

      if(this.taxes){
        if(this.taxes[0] !== undefined){
          this.hasTaxes = true
          this.taxPercent = this.taxes[0].value
        }
      }

      this.timezone = this.user.companySettings.timezone
      this.isTaxPriceIncluded = (this.user.companySettings.includeTaxInPrice == 0) ? false : true

      this.validUntil = addDays(new Date(), this.user.companySettings.proposalValidPeriod)

      await this.getContractTemplates()

      try {
        const { data } = await this.$api.get('projects').load(this.$route.params.id)

        this.project = data.project

        if(data.project.currency){
          this.currency = data.project.currency
        }
        this.contact = data.project.contact
        this.proposal.locale = data.project.locale

      } catch(e){
        //this.redirectBack()
        logException(e)
      }

      try {
        const { data } = await this.$api.get('proposal').lastReferenceNumber()
        this.lastReferenceNumber = data.lastReferenceNumber

        if(this.referenceNumber == null){
          this.referenceNumber = data.newNumber
        }

      } catch(e){
        logException(e)
      }
    },
    refreshLogo(){
      let that = this
      let image = new Image()
      image.src = this.logo.path
      image.onload = function () {
        that.logo.width = this.width
        that.logo.height = this.height
      }
    },
    initDetectChanges(){
      let elements = document.getElementsByTagName('input')
      let textAreaElements = document.getElementsByTagName('textarea')
      let that = this
      for(let i=0;i<elements.length;i++) {
        if(!(/submit/).test(elements[i].getAttribute('type'))) {
          elements[i].onchange = function(){that.detect(this)}
        }
      }
      for(let i=0;i<textAreaElements.length;i++) {
        if(!(/submit/).test(textAreaElements[i].getAttribute('type'))) {
          textAreaElements[i].onchange = function(){that.detect(this)}
        }
      }
    },
    detect(e){
      this.userMightUpdate = true
    },
    saveAndPreview(){
      this.isPreview = true
      this.save()
    },
    calculateUnitPrice(item){
      return item.price*item.quantity
    },
    autoSuggestBlurHandler(payload){
      let name = this.$refs['autoSuggestId'+payload.id][0].searchInput
      this.listofItems.find(function(item){
        if(item.id == payload.id) {
          item.name = name
        }
      })
    },
    autoSuggestAdditionalBlurHandler(payload){
      let name = this.$refs['autoSuggestId'+payload.id][0].searchInput
      this.listofAdditonalItems.find(function(item){
        if(item.id == payload.id) {
          item.name = name
        }
      })
    },
    async autoSuggestOnInputChange(text, oldText){
      if (text === null) {
        /* Maybe the text is null but you wanna do
        *  something else, but don't filter by null.
        */
        return
      }
      let that = this
      this.suggestions = []
      try {
        const postData = {
          companyId: this.user.company.id,
          name: text,
        }
        const { data } = await this.$api.get('templateProduct').search(postData)
        that.suggestions = [{ data: data.results }]

      } catch(e){
        logException(e)
      }
    },
    autoSuggestClickHandler(item) {
      this.selectedListId = item.id
    },
    autoSuggestSelectHandler(payload){
      let that = this
      let suggestedItem = payload.item

      this.listofItems.find(function(item){
        if(item.id == that.selectedListId) {
          item.name = suggestedItem.name
          item.price = suggestedItem.price
          item.description = suggestedItem.description
          item.unit = suggestedItem.unit
          item.is_quantity_selectable = suggestedItem.is_quantity_selectable
          item.quantity = suggestedItem.quantity
          item.isTaxEnable = suggestedItem.is_taxable
          item.tax = suggestedItem.tax
        }
      })
    },
    autoSuggestAdditionalSelectHandler(payload){
      let that = this
      let suggestedItem = payload.item

      this.listofAdditonalItems.find(function(item){
        if(item.id == that.selectedListId) {
          item.name = suggestedItem.name
          item.price = suggestedItem.price
          item.description = suggestedItem.description
          item.unit = suggestedItem.unit
          item.is_quantity_selectable = suggestedItem.is_quantity_selectable
          item.quantity = suggestedItem.quantity
          item.isTaxEnable = suggestedItem.is_taxable
          item.tax = suggestedItem.tax
        }
      })
    },
    dateFormatter(value) {
      return format(value, 'MMM Do, YYYY')
    },
    dateFormatSave(value) {
      return format(value, 'YYYY-MM-DD')
    },
    addAdditionalItem() {
      this.listofAdditonalItems.push({
          id: new Date().getTime(),
          name: '',
          description: '',
          quantity: 1,
          price: 0,
          isTaxEnable: this.isTaxEnable,
          isTemplate: false,
          is_quantity_selectable: false,
          tax: this.taxPercent,
          isHover:false,
      })

      this.userMightUpdate = true
    },
    removeAdditionalItem(index){
      if(this.listofAdditonalItems.length > 0) {
        this.listofAdditonalItems.splice(index, 1)
      }
    },
    addItem() {
      this.listofItems.push({
        id: new Date().getTime(),
        name: '',
        description: '',
        quantity: 1,
        price: 0,
        isTaxEnable: this.isTaxEnable,
        is_quantity_selectable: false,
        isTemplate: false,
        tax: this.taxPercent,
        isHover:false,
      })

      this.userMightUpdate = true
    },
    removeItem(index){
      if(this.listofItems.length > 0) {
        this.listofItems.splice(index, 1)
      }
    },
    switchStep(step){
      this.step = step
    },
    activeTab(step){

      let switchStep = false
      if(step == 2 && !this.isPaymentScheduleActive){
        this.isPaymentScheduleActive = true
        switchStep = true

        if(this.listofPaymentItems.length == 0){
          this.addPaymentItem()
        }
      }
      if(step == 3 && !this.isContractIncluded){
        this.isContractIncluded = true
        switchStep = true
      }

      if(switchStep){
        this.switchStep(step)
      }
      this.calculateSteps(step)
    },
    previousStep(){

      if(!this.isPaymentScheduleActive) {
        this.step--
      }
      this.step--

      if(this.step < 1){
        this.$router.push(`/projects/${this.$route.params.id}`)
      }
    },
    nextStep(){

      let requireListItemsLength = this.listofItems.length

      if(this.step == 1 && requireListItemsLength == 0){
        this.$toasted.global.general_error({
          message : 'Please add at least one item to continue.'
        })

        return false
      }

      if(!this.isPaymentScheduleActive) {
        this.step++
      }

      if(this.step == 2 && this.isPaymentScheduleActive){
        if(!this.paymentItemCheck()){
          return false
        }
      }

      this.step++
      this.calculateSteps(this.step)

    },
    calculateSteps(step){

      // No contract and no payment schedule
      if(!this.isContractIncluded && !this.isPaymentScheduleActive){
        this.lastStep = 1
        if(this.step > 1){
          this.step = 1
          return
        }
      }

      // Have contract and no payment schedule
      if(this.isContractIncluded && !this.isPaymentScheduleActive){
        this.lastStep = 3
        if(this.step == 2){
          this.step = 1
          return
        }
      }

      // No contract and have payment schedule
      if(!this.isContractIncluded && this.isPaymentScheduleActive){
        this.lastStep = 2
        if(this.step > 2){
          this.step = 2
          return
        }
      }

      if(this.isContractIncluded){
        this.lastStep = 3
      }

      if(step == 2 && this.isPaymentScheduleActive){
        this.step = 2
      }

      if(step == 3 && this.isContractIncluded){
        this.step = 3
      }
    },
    async alertSave(){
      const canSave = await this.$alert.confirm({
        title: 'This proposal has already been sent or accepted.',
        text: 'If you save and update this, the client will have to re-accept this proposal. Do you want to continue?',
      })

      if(canSave) {
        this.save(true, false)
      }
    },
		save(saveAndEditLater = false, saveAndSend = false) {
      let error = false

      if(error){
        return false
      }

      if(!this.paymentItemCheck()){
        return false
      }

      if(this.referenceNumber == ''){
        this.$toasted.global.general_error({
          message : 'The quote number cannot be empty.'
        })
        return false
      }

      if(this.isContractTemplate){
        if(this.contractName == ''){
          this.$toasted.global.general_error({
            message : 'Please fill in a contract template name.'
          })
          return false
        }
      }

      if(!this.issuedAt){
        this.$toasted.global.general_error({
          message : 'Issued date cannot be empty.'
        })
        return false
      }

      if(!this.validUntil){
        this.$toasted.global.general_error({
          message : 'Due date cannot be empty.'
        })
        return false
      }

      if(this.total < 0){
        this.$toasted.global.general_error({
          message : 'Total cannot be less than 0.'
        })
        return false
      }

      if(this.doSave(saveAndEditLater, saveAndSend)){
        return true
      }
		},
    paymentItemCheck(){
      let error = false
      let totalPercentage = 0
      let that = this

      this.listofPaymentItems.forEach(function(item) {
        if((item.type == 'project_date' || item.type == 'custom_date') && !that.project.date){
          that.$modal.show('projectDateModal')
          error = true
        }
        totalPercentage += Number(item.percentage)
      })

      if(totalPercentage != 100 && this.isPaymentScheduleActive){
        this.$toasted.global.general_error({
          message : 'Payment schedule total percentage should equal to 100%.'
        })
        error = true
      }

      if(error){
        return false
      }
      return true
    },
    async doSave(saveAndEditLater = false, saveAndSend = false) {

      this.isSaving = true
      let that = this

      //Parse project date especially if is loading straight from template
      this.listofPaymentItems.forEach(function(item) {
        if(item.type == 'fixed_date'){
          let date = (item.date) ? format(item.date, 'YYYY-MM-DD') : null
          item.date = date
        }
      })

      try {
        const postData = {
          companyId: this.user.company.id,
          proposalId: this.proposalId,
          projectId: this.project.id,
          data: this._data,
          currencyId: this.currency.id,
          issuedAt:this.dateFormatSave(this.issuedAt),
          validUntil:this.dateFormatSave(this.validUntil),
          taxPercent: this.taxPercent,
          subTotal: this.subTotal,
          taxTotal: this.taxTotal,
          discountTotal: this.discountTotal,
          total: this.total,
        }

        const { data } = await this.$api.get('proposal').save(postData)

        this.proposal = data.proposal
        this.proposalId = data.proposal.id

        this.listofPaymentItems = []
        data.listofPaymentItems.forEach(function(item) {
          item.is_delete = false
          item.date = new Date(item.date)
          that.listofPaymentItems.push(item)
        })

        this.generateEmailContent()
        await this.loadDraft()

        if(this.isContractIncluded){
          this.contractId = data.contract.id
          this.contractContent = data.contract.content
        }

        if(this.isContractTemplate){
          this.isContractTemplate = false
          this.contractName = ''
          await this.getContractTemplates()
        }

        if(saveAndEditLater){
          this.$router.push(`/projects/${this.$route.params.id}`)
        }

        if(!this.isPreview && (this.$route.query.load && this.loadTemplateId && this.proposalId) || saveAndSend){
          window.open(`/projects/${this.$route.params.id}/proposal/${this.proposalId}?send=1`, '_self')
        }

        if(this.isPreview){
          this.$router.push(`/proposals/preview/${this.proposalId}`)
          // let popUp = window.open(`/proposals/preview/${this.proposalId}`, '_blank')
          // if(!popUp || popUp.closed || typeof popUp.closed=='undefined')
          // {
          //   this.$toasted.global.general_error({
          //     message : 'Opening a new window is blocked. Please add us to your exception list.'
          //   })
          //   return false
          // }
        }

        if(!this.isPreview && !saveAndSend){
          this.email.emailId = this.proposalId
          this.showEmailSlide = true
        }

        this.$toasted.global.general_success({
          message : 'Proposal saved.'
        })

        this.isPreview = false
        this.isSaved = true
        this.isSaving = false

        return true

      } catch(e){
        this.$toasted.global.api_error(e)
        logException(e)
      }
    },
    changeView(view){
      this.view = view
    },
    addPaymentItem() {

      let percentage = 0
      let totalPaymentItems = 0

      this.listofPaymentItems.push({
          id: new Date().getTime(),
          percentage: percentage,
          type: 'on_receive',
          date: this.dateFormatSave(new Date()),
          custom_length: 1,
          custom_length_type: 'days',
          is_before_project_date: 1,
          is_delete: false,
      })

      this.listofPaymentItems.forEach(function(item) {
          if(!item.is_delete){
            totalPaymentItems++
          }
      })

      this.listofPaymentItems.forEach(function(item) {
          if(!item.is_delete){
            let itemPercentage = (100 / totalPaymentItems)
            let number = parseFloat(Math.round((itemPercentage + Number.EPSILON) * 100) / 100)

            item.percentage = (number % 1 != 0) ? number.toFixed(2) : number
            percentage += item.percentage
          }
      })

      if(percentage < 0 || percentage == 0){
        this.$toasted.global.general_error({
          message : 'Payment schedule already exceed 100%.'
        })
        return false
      }

      this.userMightUpdate = true
    },
    removePaymentItem(index){
      if(this.listofPaymentItems.length > 0) {
        this.listofPaymentItems[index].percentage = 0
        this.listofPaymentItems[index].is_delete = true
      }
    },
    blurPaymentItemPercent(e){
      if(isNaN(e.target.value)){
        e.target.value = 0
      }
    },
    async getContractTemplates(){
      try {
        const { data } = await this.$api.get('templateContract').list(this.user.company.id)
        this.contracts = data.contracts
      } catch(e){
        logException(e)
      }
    },
    changeContract(){
      if(this.selectedContractTemplate > 0){
        let contract = this.contracts.find(i => i.id === this.selectedContractTemplate)
        this.contractName = contract.name
        this.contractContent = contract.content

        this.editorUpdatePending = true
      }
    },
    async getProposal(id, type = null){

      let that = this

      try {

        let result = {}
        if(type == 'template'){
          result = await this.$api.get('templateProposal').detail(this.user.company.id, id)
          this.subject = result.data.proposal.name
        } else {
          result  = await this.$api.get('proposal').detail(id, 'edit')
          this.name = result.data.proposal.name
          this.subject = result.data.proposal.subject
        }

        let proposal = result.data.proposal

        this.actionDates = result.data.actionDates
        this.proposal = result.data.proposal


        if(proposal.reference_number){
          this.referenceNumber = proposal.reference_number
        }

        this.isTaxEnable = proposal.is_tax_enable
        this.isTaxPriceIncluded = proposal.is_tax_price_included
        this.isContractIncluded = proposal.has_contract
        this.isInvoiceGenerated = proposal.has_invoice
        this.isPaymentScheduleActive = proposal.has_payment_schedules
        this.taxPercent = proposal.tax
        this.discount = proposal.discount
        this.notes = proposal.notes
        this.proposal.locale = this.project.locale

        this.validUntil = addDays(new Date(), proposal.valid_period)

        if(type != 'template'){
          this.currency = proposal.currency
          this.proposalId = proposal.id

          if(proposal.issued_at){
            let date = new Date(proposal.issued_at)
            this.issuedAt  = convertToTimeZone(date, { timeZone: 'Etc/UTC' })
          }

          if(proposal.valid_until){
            let date = new Date(proposal.valid_until)
            this.validUntil  = convertToTimeZone(date, { timeZone: 'Etc/UTC' })
          }

          this.generateEmailContent()
          await this.loadDraft()
        }

        if(!this.hasTaxes){
          this.isTaxEnable = false
        }

        let that = this
        proposal.items.forEach(function(item) {
          item.isTaxEnable = (!that.hasTaxes) ? false : item.is_taxable

          let quantity = String(item.quantity)
          if(quantity.includes(".00")){
            item.quantity = parseInt(item.quantity)
          }

          if(item.is_optional){
            that.listofAdditonalItems.push(item)
          } else {
            that.listofItems.push(item)
          }
        })

        if(proposal.payment_schedules){
          this.listofPaymentItems = []
          proposal.payment_schedules.forEach(function(item) {

            item.is_delete = false
            let date = new Date(item.date)
            item.date  = convertToTimeZone(date, { timeZone: 'Etc/UTC' })

            that.listofPaymentItems.push(item)
          })
        }

        if(proposal.contract){
          this.contractId = proposal.contract.id
          this.contractContent = proposal.contract.content
        }

        if(this.$route.query.send){
          this.email.emailId = this.proposalId
          this.showEmailSlide = true
        }

        this.loading = false

      } catch(e){
        logException(e)
      }
    },
    updateEmailTags(newTags){
      this.email.autocompleteEmailTagItems = []
      this.email.emailTags = newTags
    },
    generateEmailContent(){

      this.email.emailTags = []
      this.email.emailTags.push({
        text: this.contact.email,
      })

      const signature = (!this.user.me.email_signature) ? '' : this.user.me.email_signature
      const link = `${config.frontBaseUrl}/view/${this.proposal.project.id}/proposal/${this.proposal.token}`

      this.email.contentEmail = `<p>Hi ${this.contact.first_name}, </p><p></p><p>I hereby send you my proposal! Please contact me if you have any questions or if things are not clear. I’m glad to help you further. <p><p></p><p>Link to proposal: <a href="@documentLink">@documentLink</a> </p><p>${signature}</p>`
      this.email.emailUpdatePending = true
    },
    showEmailSlideIn(){
      let saveAndSend = false
      this.isPreview = false

      if(!this.proposalId){
        saveAndSend = true
      }
      this.save(false, saveAndSend)
    },
    async loadMailTemplates(){
      try {
        const { data } = await this.$api.get('templateMail').list(this.user.company.id)
        this.email.templates = []

        data.mails.forEach(template => {
          this.email.templates.push({template})
        })
      } catch(e){
        logException(e)
      }
    },
    async loadDraft(){

      this.email.selectEmailTemplate = ''

      try {
        const { data } = await this.$api.get('mail').loadDraft(`${this.proposalId}`, 'proposal')
        if(data.result != 'Empty'){

          this.email.emailTags = []
          data.toEmails.forEach(email => {
            this.email.emailTags.push({
              text: email,
            })
          })

          this.email.fromEmail = data.from
          this.email.fromEmailView = `From: ${this.user.companySettings.senderName} <${this.user.companySettings.senderEmail}>`
          this.email.subjectEmail = data.subject
          this.email.contentEmail = data.content
          this.email.emailUpdatePending = true
        }
      } catch(e){
        logException(e)
      }
    },
    async hideEmailSlideIn(){
      this.showEmailSlide = false
    },
    async savedEmailSlideIn(){
      this.showEmailSlide = false
      this.isSendingMail = false
      this.$router.push(`/projects/${this.$route.params.id}`)
    },
    async copyShareLink(){
      if(this.proposal.state == 'draft'){
        const can = await this.$alert.confirm({
          title: 'Proposal has not been sent out',
          text: 'In order to copy a share link, we will mark this proposal as sent.'
        })

        if (can) {
          try {
            await this.$api.get('proposal').updateState(this.proposalId, 'sent')
              const link = `${config.frontBaseUrl}/view/${this.proposal.project.id}/proposal/${this.proposal.token}`
              let dummy = document.createElement('textarea')
              document.body.appendChild(dummy)
              dummy.value = link
              dummy.select()
              document.execCommand('copy')
              document.body.removeChild(dummy)

              this.$toasted.global.general_success({
                message : 'Share link has been copied to your clipboard.'
              })

              setTimeout(() => {
                window.location.reload()
              }, 500)

          } catch(e){
            this.$toasted.global.api_error(e)
            logException(e)
          }
        }
      } else {
        const link = `${config.frontBaseUrl}/view/${this.proposal.project.id}/proposal/${this.proposal.token}`
        let dummy = document.createElement('textarea')
        document.body.appendChild(dummy)
        dummy.value = link
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)

        this.$toasted.global.general_success({
          message : 'Share link has been copied to your clipboard.'
        })
      }
    },
    async deleteItem(){

      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete this proposal?',
        text: 'If you delete this, sent item will no longer accessible by the client.',
      })

      if (canDelete) {
        try {
          try {
            const postData = {
              proposalId: this.proposalId,
            }
            await this.$api.get('proposal').delete(postData)
            this.$router.push(`/projects/${this.$route.params.id}`)

          } catch(e){
            this.$toasted.global.api_error(e)
            logException(e)
          }

        } catch(e){
          logException(e)
        }
      }
    },
    async markAsSent(){
      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this proposal as sent?',
      })

      if (can) {
        try {
          await this.$api.get('proposal').updateState(this.proposalId, 'sent')
          setTimeout(() => {
            window.location.reload()
          }, 500)

        } catch(e){
          this.$toasted.global.api_error(e)
          logException(e)
        }
      }
    },
    async markAsUnsent(){

      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this proposal as draft?',
      })

      if (can) {
        try {
          await this.$api.get('proposal').updateState(this.proposalId, 'draft')
          setTimeout(() => {
            window.location.reload()
          }, 500)

        } catch(e){
          this.$toasted.global.api_error(e)
          logException(e)
        }
      }
    },
    editContact(){
      this.showContactSlide = true
    },
    async hideContactSlideIn(){
      this.showContactSlide = false
    },
    async savedContactSlideIn(contactId){
      try {
        const { data } = await this.$api.get('contacts').load(contactId)

        this.contact = data.contact
        this.showContactSlide = false

      } catch(e){
        logException(e)
      }
    },
    async fileUpload(){

      let that = this
      let _URL = window.URL || window.webkitURL
      let file = this.$refs.file.files[0]
      this.uploadLoading = true

      if (file.size > 5000 * 1024 || (file.type != 'image/jpeg' && file.type != 'image/jpg' && file.type != 'image/png')) {
        this.$toasted.global.general_error({
          message : 'Upload an image within 5mb, PNG or JPG format and a minimum resolution of 500px width or 500px height.'
        })
        this.uploadLoading = false
        return
      }

      let img = new Image()
      img.onload = function () {
        let hasError = false
        if(this.width > this.height){
          if(this.width < 500){
            hasError = true
          }
        }
        if(this.width < this.height){
          if(this.height < 500){
            hasError = true
          }
        }
        if(this.width == this.height){
          if(this.width < 500 && this.height < 500){
            hasError = true
          }
        }
        if(hasError){
          that.uploadLoading = false
          that.$toasted.global.general_error({
            message : 'Upload an image within 5mb, PNG or JPG format and a minimum resolution of 500px width or 500px height.'
          })
          return false
        }
      }
      img.src = _URL.createObjectURL(file)

      let formData = new FormData()
      formData.append('image', file)

      try {
        await this.$store.dispatch('company/uploadLogo', formData)

        try {
            await this.$store.dispatch('company/refreshCompanyDetails')
            const company = this.$store.getters['company/details']
            this.logo.path = company.logo
            this.refreshLogo()

            auth.refreshUser()
         } catch(e) {
          this.$toasted.global.api_error(e)
        }
      } catch(e) {
        this.$toasted.global.general_error({
          message : 'Please upload an image within 5mb, PNG or JPG format and a minimum resolution of 500x500 px.'
        })
      }

      this.uploadLoading = false
    },
    async loadTemplate(id, type){
      try {
        const data = await this.$store.dispatch('template/loadTemplate', id)
        if(data == 'success'){
          this.$router.push(`/projects/${this.projectId}/proposal/create?load=1`)
        }
      } catch(e) {
        this.$toasted.global.api_error(e)
      }
    },
    async checkIntroVideo(){
      let showModal = false
      // let showModal = true
      // this.user.me.companySettings.watchIntroVideos.forEach(video => {
      //   if(video == 'proposal-edit'){
      //     showModal = false
      //   }
      // })

      if(showModal){
        this.isShowVideoModal = true
      }
    },
    showVideoModal(){
      this.isShowVideoModal = true
    },
    closeVideoModal(){
      this.isShowVideoModal = false
    },
    showTaxModal(){
      this.isShowTaxModal = true
    },
    closeTaxModal(){
      this.isShowTaxModal = false
    },
    async savedTaxModal(taxes, defaultTaxPercent){
      this.isShowTaxModal = false
      this.isTaxEnable = true
      this.hasTaxes = true
      this.taxes = taxes
      this.taxPercent = defaultTaxPercent
      this.$modal.hide('addTaxModal')
    },
	},
}
</script>
